import React from "react";



function NavBar() {

  return (

    <nav className="navbar fixed-top navbar-expand-lg">
      <div className="container-fluid">
        <a className="cesslogo" href="/" >
          <img src="https://www.cessuniversidad.com/assets/images/Logo_slogan_web.png" alt="Bootstrap"  />
        </a>
      
      </div>


    </nav>

    

  );
}

export default NavBar;


